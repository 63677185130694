import gql from "graphql-tag";

const GET_LOCATIONS = gql`
  query getLocations {
    locations
      @rest(type: "getLocations", path: "/get_locations", endpoint: "v1") {
      available_location
    }
  }
`;

export { GET_LOCATIONS };
