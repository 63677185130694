const esTranslations = {
  translation: {
    home: {
      sites_nearby: "Sitios de prueba cercanos",
      results_found: "resultados encontrados",
      coming_soon: {
        title: "Vaya, no pudimos encontrar ninguna ubicación",
        intro_text:
          "Visite <2>GoGetTested.com</2> para ver las actualizaciones sobre las ubicaciones próximamente.",
      },
    },
    components: {
      loader: {
        title: "Cargando",
        intro_text:
          "Las cosas están tomando un poco más de tiempo de lo habitual.",
        no_refresh_text: "Por favor, no actualice su navegador.",
      },
      location_card: {
        selected: {
          title: "Ubicación seleccionada",
        },
        operated_by: "Operado por",
        stats: {
          test_slots: {
            label: "Ranuras de prueba",
          },
          time_to_results: {
            label: "Tiempo para los resultados",
          },
          test_wait_time: {
            label: "Tiempo de espera de la prueba",
          },
          next_available: {
            label: "Siguiente disponible",
          },
        },
        button: {
          selected: "Seleccionado",
          unselected: "Seleccione esta ubicación",
          book: "Reservar ahora",
          call: "Llamada",
        },
      },
      mapbox_location_autocomplete: {
        form: {
          address: {
            label: "Busca tu ubicación",
            hint: "Escriba su direccion",
          },
        },
      },
      location_services_list: {
        title: "Servicios",
      },
    },
    common: {
      next: "Siguiente",
      confirm: "Confirme",
      error: "Lo siento, ha ocurrido un error",
      page_not_found: "Lo siento, no pudimos encontrar esa página",
      go_home: "Ir al tablero de mandos",
      learn_more: "Aprende más",
      continue: "Continúa",
      total: "Total",
      service_covid_19_test: "Prueba COVID-19",
      service_consult: "Consultar",
      service_flu_shot: "Vacuna contra la gripe",
    },
  },
};

export { esTranslations };
