import React, { useState, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, InputAdornment } from "@material-ui/core";
import Geocoding from "@mapbox/mapbox-sdk/services/geocoding";
import { Search } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const MapboxLocationAutocomplete = ({
  onUpdateLocation,
  accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const geocodingClient = Geocoding({
    accessToken,
  });

  useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  /**
   * Handle search location
   */
  const handleSearchLocation = (location) => {
    if (!location) return;

    geocodingClient
      .forwardGeocode({
        query: location,
        limit: 5,
        countries: ["US"],
        autocomplete: true,
      })
      .send()
      .then((response) => {
        const { body } = response;
        const { features = [] } = body;

        setOptions(features);
      });
  };

  const handleUpdateLocation = (location) => {
    const [lng, lat] = location.center;

    if (!lng || !lat) return;

    onUpdateLocation({ lat, lng });
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(e, value) => value && handleUpdateLocation(value)}
      getOptionSelected={(option, value) =>
        option.place_name === value.place_name
      }
      getOptionLabel={(option) => option.place_name}
      options={options}
      onInputChange={(e) =>
        e.target.value && handleSearchLocation(e.target.value)
      }
      noOptionsText={t(
        "components.mapbox_location_autocomplete.form.address.hint"
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(
            "components.mapbox_location_autocomplete.form.address.label"
          )}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export { MapboxLocationAutocomplete };
