import gql from "graphql-tag";

const GET_LOCATIONS_NEAR_ME = gql`
  query getLocationsNearMe(
    $latitude: Number!
    $longitude: Number!
    $distance: Integer!
  ) {
    locations(latitude: $latitude, longitude: $longitude, distance: $distance)
      @rest(
        type: "getLocationsNearMe"
        path: "/get_locations_near_me/{args.latitude}/{args.longitude}/{args.distance}"
        endpoint: "v1"
      ) {
      available_location
    }
  }
`;

export { GET_LOCATIONS_NEAR_ME };
