import React from "react";
import {
  Paper,
  Typography,
  Grid,
  makeStyles,
  Box,
  Divider,
} from "@material-ui/core";
import { LocationServicesList } from "./LocationServicesList";
import { MoreButton } from "./MoreButton";
import { useTranslation } from "react-i18next";
import * as queryString from "query-string";
import { useLocation } from "react-router-dom";

const useClasses = makeStyles((theme) => ({
  statsContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: `linear-gradient(to bottom, ${theme.palette.divider}, ${theme.palette.divider}) 33.33333333% repeat-y, linear-gradient(to bottom, ${theme.palette.divider}, ${theme.palette.divider}) 66.66666666% repeat-y`,
    backgroundSize: "1px 100%",
  },
  statsValue: {
    fontSize: "18px",
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  },
  statsLabel: {
    fontSize: "0.8125rem",
    fontWeight: 700,
  },
  legalText: {
    color: theme.palette.grey[500],
    fontSize: "12px",
  },
}));

const LocationStat = ({ label, value }) => {
  const classes = useClasses();

  return (
    <Box textAlign="center" p={1}>
      <Typography
        align="center"
        gutterBottom
        component="div"
        style={{ fontSize: "12px" }}
      >
        <strong>{label}</strong>
      </Typography>
      <Typography
        align="center"
        variant="h1"
        component="div"
        className={classes.statsValue}
      >
        {value}
      </Typography>
    </Box>
  );
};

const LocationCard = ({ location, showMap = true, order }) => {
  const { t } = useTranslation();
  const classes = useClasses();
  const routerLocation = useLocation();

  const getBookingUrl = (location) => {
    const { lang = "en" } = queryString.parse(routerLocation.search);

    return location.booking_url
      ? location.booking_url
      : `${process.env.REACT_APP_BOOKING_URL}?location_id=${location.id}&lang=${lang}`;
  };

  const locationUnavailable = (location) =>
    !location.slots_available || parseInt(location.slots_available) <= 0;

  return (
    <Paper elevation={0}>
      <Box>
        <Grid container justify="space-between" direction="column">
          <Grid item>
            <Box p={2} textAlign="left">
              <Grid container spacing={2}>
                {showMap && (
                  <Grid item>
                    <img src={location.map_thumbnail} alt="" />
                  </Grid>
                )}
                <Grid item xs>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography variant="h3" component="h2">
                        <strong>{order}.</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography gutterBottom variant="h3" component="h2">
                        <strong>{location.name}</strong>
                      </Typography>
                      <Typography gutterBottom>{location.address}</Typography>
                      <Typography gutterBottom>
                        {t("components.location_services_list.title")}:{" "}
                        <LocationServicesList
                          servicesAvailable={location.services_available}
                        />
                      </Typography>
                      <Typography className={classes.legalText}>
                        {t("components.location_card.operated_by")}{" "}
                        {location.operated_by || "WellHealth Management LLC"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {!location.external && (
            <>
              <Grid item style={{ width: "100%" }}>
                <Box className={classes.paperBottom}>
                  <Box className={classes.statsContainer}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <LocationStat
                          label={t(
                            "components.location_card.stats.test_slots.label"
                          )}
                          value={location.slots_available}
                        />
                      </Grid>
                      <Grid item xs>
                        <LocationStat
                          label={t(
                            "components.location_card.stats.time_to_results.label"
                          )}
                          value={`${location.result_time_hours}`}
                        />
                      </Grid>
                      <Grid item xs>
                        <LocationStat
                          label={t(
                            "components.location_card.stats.test_wait_time.label"
                          )}
                          value={`${location.wait_time_mins}`}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
          <Grid item>
            {!location.external && (
              <>
                <Box py={2}>
                  <Typography
                    align="center"
                    component="div"
                    className={classes.statsLabel}
                  >
                    {t("components.location_card.stats.next_available.label")}:{" "}
                    <Typography
                      variant="h6"
                      component="p"
                      style={{ fontWeight: "700", color: "#000" }}
                    >
                      {location.next_test_date}
                    </Typography>
                  </Typography>
                </Box>
                <Divider />
              </>
            )}
            <Box textAlign="right" p={2}>
              {location.external ? (
                <>
                  {location.website ? (
                    <MoreButton
                      variant="contained"
                      color="primary"
                      disabled={true}
                      style={{ color: "#000" }}
                    >
                      {location.website}
                    </MoreButton>
                  ) : location.external_phone ? (
                    <MoreButton
                      variant="contained"
                      color="primary"
                      disabled={true}
                      style={{ color: "#000" }}
                    >
                      {t("components.location_card.button.call")}{" "}
                      {location.external_phone}
                    </MoreButton>
                  ) : null}
                </>
              ) : (
                <MoreButton
                  href={getBookingUrl(location)}
                  target="_blank"
                  rel="noopener"
                  variant="contained"
                  color="primary"
                  disabled={locationUnavailable(location)}
                >
                  {t("components.location_card.button.book")}
                </MoreButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export { LocationCard };
