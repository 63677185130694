import React from "react";
import { Switch, Route } from "react-router-dom";
import { Home } from "./views";

const Routes = () => {
  return (
    <Switch>
      <Route path={`/:lat/:lng/:zoom?`}>
        <Home />
      </Route>
      <Route path={`/`} exact component={Home} />
    </Switch>
  );
};

export { Routes };
