import React from "react";
import {
  IconButton,
  SvgIcon,
  makeStyles,
  Box,
  Dialog,
} from "@material-ui/core";
import { ReactComponent as LocationPinIcon } from "../images/location-pin.svg";
import { Marker } from "react-map-gl";
import { LocationCard } from "./LocationCard";
import { theme } from "../theme";
import { Close } from "@material-ui/icons";

const useClasses = makeStyles((theme) => ({
  iconButton: {
    position: "relative",
  },
  iconOrder: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -90%)",
    top: "50%",
    fontSize: "12px",
    fontWeight: 700,
    color: theme.palette.common.black,
  },
  popupInner: {
    width: "300px",
    [theme.breakpoints.up("md")]: {
      width: "400px",
    },
  },
  dialogCloseButton: {
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

const MapLocationPopup = ({
  location,
  order,
  showPopup,
  onClickPin,
  onClosePopup,
}) => {
  const classes = useClasses();

  return (
    <>
      <Marker
        key={`marker-location-${location.id}`}
        latitude={location.lat}
        longitude={location.lng}
        offsetTop={-24}
        offsetLeft={-28}
      >
        <IconButton
          className={classes.iconButton}
          onClick={() => onClickPin(location.id)}
        >
          <SvgIcon fontSize="large">
            <LocationPinIcon style={{ fill: theme.palette.primary.main }} />
          </SvgIcon>
          <Box className={classes.iconOrder}>{order}</Box>
        </IconButton>
      </Marker>
      {showPopup && (
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={true}
          onClose={() => onClosePopup()}
        >
          <Box p={1} textAlign="right" className={classes.dialogCloseButton}>
            <IconButton onClick={() => onClosePopup()} size="small">
              <Close />
            </IconButton>
          </Box>
          <LocationCard location={location} showMap={false} order={order} />
        </Dialog>
      )}
    </>
  );
};

export { MapLocationPopup };
