import React from "react";
import { ReactComponent as ServiceFluShotIcon } from "../images/service-flu-shot.svg";
import { ReactComponent as ServiceConsultationIcon } from "../images/service-consultation.svg";
import { ReactComponent as Covid19TestIcon } from "../images/service-covid-19-test.svg";
import {
  SERVICE_COVID_19_TEST,
  SERVICE_CONSULT,
  SERVICE_FLU_SHOT,
} from "./availableServices";

const API_SERVICE_MAP = {
  [SERVICE_COVID_19_TEST]: {
    label: "COVID-19 Test",
    translation_key: "common.service_covid_19_test",
    icon: <Covid19TestIcon />,
  },
  [SERVICE_CONSULT]: {
    label: "Consult",
    translation_key: "common.service_consult",
    icon: <ServiceConsultationIcon />,
  },
  [SERVICE_FLU_SHOT]: {
    label: "Flu Shot",
    translation_key: "common.service_flu_shot",
    icon: <ServiceFluShotIcon />,
  },
};

export { API_SERVICE_MAP };
