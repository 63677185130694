import React from "react";
import { Typography } from "@material-ui/core";
import { API_SERVICE_MAP } from "../constants";
import { useTranslation } from "react-i18next";

const LocationServicesList = ({ servicesAvailable = [] }) => {
  const { t } = useTranslation();
  const validServices = (service) => API_SERVICE_MAP[service.sku];

  if (servicesAvailable.filter(validServices).length <= 0) return null;

  return (
    <>
      {servicesAvailable.filter(validServices).map((service, index) => (
        <Typography component="span" key={`service-${index}`}>
          {t(API_SERVICE_MAP[service.sku].translation_key)}
          {index + 1 < servicesAvailable.length && ", "}
        </Typography>
      ))}
    </>
  );
};

export { LocationServicesList };
