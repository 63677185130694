import React from "react";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { RestLink } from "apollo-link-rest";

const AuthorizedApolloProvider = ({ children }) => {
  /**
   * Setup REST link
   */
  const restLink = new RestLink({
    uri: `${process.env.REACT_APP_API_HOST}/api`,
    endpoints: {
      v1: `${process.env.REACT_APP_API_HOST}/api`,
      v2: `${process.env.REACT_APP_API_HOST}/api/v2`,
    },
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: restLink,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { AuthorizedApolloProvider };
