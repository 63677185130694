const enTranslations = {
  translation: {
    home: {
      sites_nearby: "Testing sites nearby",
      results_found: "results found",
      coming_soon: {
        title: "Oops, we couldn't find any locations",
        intro_text:
          "Please check <2>GoGetTested.com</2> for updates on locations coming soon.",
      },
    },
    components: {
      loader: {
        title: "Loading",
        intro_text: "Things are taking a little longer than usual.",
        no_refresh_text: "Please do not refresh your browser.",
      },
      location_card: {
        selected: {
          title: "Selected location",
        },
        operated_by: "Operated by",
        stats: {
          test_slots: {
            label: "Test Slots",
          },
          time_to_results: {
            label: "Time to Results",
          },
          test_wait_time: {
            label: "Test Wait Time",
          },
          next_available: {
            label: "Next Available",
          },
        },
        button: {
          selected: "Selected",
          unselected: "Select this location",
          book: "Book Now",
          call: "Call",
        },
      },
      mapbox_location_autocomplete: {
        form: {
          address: {
            label: "Search for your location",
            hint: "Type your address",
          },
        },
      },
      location_services_list: {
        title: "Services",
      },
    },
    common: {
      next: "Next",
      confirm: "Confirm",
      error: "Sorry, an error occured",
      page_not_found: "Sorry, we couldn't find that page",
      go_home: "Go to dashboard",
      learn_more: "Learn More",
      continue: "Continue",
      total: "Total",
      service_covid_19_test: "COVID-19 Test",
      service_consult: "Consult",
      service_flu_shot: "Flu Shot",
    },
  },
};

export { enTranslations };
