import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import ReactMapGL, { NavigationControl } from "react-map-gl";
import { makeStyles } from "@material-ui/styles";

const useClasses = makeStyles((theme) => ({
  navigationControl: {
    position: "absolute",
    right: theme.spacing(4.5),
    top: theme.spacing(1),
  },
}));

const MapboxMap = ({
  latitude = 32.895367,
  longitude = -96.788849,
  zoom = 9,
  offsetTop = 0,
  children,
  scrollZoom = true,
}) => {
  const classes = useClasses();
  const [viewport, setViewport] = useState({});

  useEffect(() => {
    setViewport((viewport) => ({ ...viewport, latitude, longitude, zoom }));
  }, [latitude, longitude, zoom]);

  return (
    <ReactMapGL
      style={{
        position: "absolute",
        top: offsetTop,
        right: 0,
        bottom: 0,
        left: 0,
      }}
      {...viewport}
      height="auto"
      width="100%"
      mapStyle="mapbox://styles/mapbox/streets-v11"
      scrollZoom={scrollZoom}
      onViewportChange={(viewport) => {
        setViewport(viewport);
      }}
    >
      {children}
      <Box className={classes.navigationControl}>
        <NavigationControl />
      </Box>
    </ReactMapGL>
  );
};

export { MapboxMap };
