import React from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { AuthorizedApolloProvider } from "./ApolloClient";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./theme";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import { LanguageProvider } from "./contexts";

const App = () => {
  return (
    <AuthorizedApolloProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <LanguageProvider>{() => <Routes />}</LanguageProvider>
        </BrowserRouter>
      </ThemeProvider>
    </AuthorizedApolloProvider>
  );
};

export default App;
