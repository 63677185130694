import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Box,
  makeStyles,
  CircularProgress,
  Typography,
  Divider,
  useMediaQuery,
  Paper,
  Link,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import { GET_LOCATIONS_NEAR_ME } from "../../queries";
import { MapboxMap } from "../../components/MapboxMap";
import { LocationCard } from "../../components/LocationCard";
import { MapboxLocationAutocomplete } from "../../components/MapboxLocationAutocomplete";
import { MapLocationPopup } from "../../components/MapLocationPopup";
import { theme } from "../../theme";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const useClasses = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      height: "100vh",
      maxHeight: "100vw",
    },
  },
  mapContainer: {
    position: "relative",
    height: "50vh",
    [theme.breakpoints.up("sm")]: {
      height: "auto",
    },
  },
  locationsContainer: {
    overflowY: "scroll",
    maxHeight: "50vh",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "100vh",
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

const LocationsComingSoon = () => {
  const { t } = useTranslation();

  return (
    <Paper>
      <Box p={2}>
        <Typography gutterBottom variant="h3" component="h2">
          <strong>{t("home.coming_soon.title")}</strong>
        </Typography>
        <Typography>
          <Trans i18nKey="home.coming_soon.intro_text">
            Please check{" "}
            <Link href="https://gogettested.com" rel="noopener">
              GoGetTested.com
            </Link>{" "}
            for updates on locations coming soon.
          </Trans>
        </Typography>
      </Box>
    </Paper>
  );
};

const LocationsList = ({ locations }) => {
  return (
    <>
      {locations.map((location, index) => (
        <Box key={`location-${location.id}`} mb={2}>
          <LocationCard location={location} order={index + 1} showMap={false} />
        </Box>
      ))}
    </>
  );
};

const Home = () => {
  const { t } = useTranslation();
  const appBar = useRef(null);
  const classes = useClasses();
  const params = useParams();

  const [openLocation, setOpenLocation] = useState();
  const [locations, setLocations] = useState([]);
  const [mapConfig, setMapConfig] = useState({
    latitude: 32.779167,
    longitude: -96.808891,
    zoom: 8,
  });

  /**
   * Get locations from API
   */
  const [getLocations, { loading: loadingLocations }] = useLazyQuery(
    GET_LOCATIONS_NEAR_ME,
    {
      onCompleted: ({ locations }) => {
        const { available_location = [] } = locations;

        if (Array.isArray(available_location)) setLocations(available_location);
      },
    }
  );

  /**
   * Get locations when map config changes
   */
  useEffect(() => {
    getLocations({
      variables: {
        longitude: mapConfig.longitude,
        latitude: mapConfig.latitude,
        distance: 50,
      },
    });
  }, [mapConfig, getLocations]);

  /**
   * Set map config to user location on mount
   */
  useEffect(() => {
    const { lat, lng, zoom = 8 } = params;

    if (lat && lng && zoom) {
      setMapConfig({
        latitude: parseFloat(lat),
        longitude: parseFloat(lng),
        zoom: parseInt(zoom),
      });
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        const newConfig = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };

        setMapConfig((config) => ({ ...config, ...newConfig }));
      });
    }
  }, [params]);

  const handleUpdateLocation = ({ lat, lng }) => {
    if (!lng || !lat) return;

    setMapConfig((config) => ({ ...config, latitude: lat, longitude: lng }));
  };

  const handleClickPin = (locationId) => {
    setOpenLocation(locationId);
  };

  const handleClosePopup = () => setOpenLocation();

  return (
    <>
      <Grid
        container
        direction={
          useMediaQuery(theme.breakpoints.up("sm")) ? "row-reverse" : "row"
        }
        className={classes.gridContainer}
      >
        <Grid
          item
          xs={12}
          sm={7}
          md={8}
          lg={9}
          className={classes.mapContainer}
        >
          <MapboxMap
            {...mapConfig}
            offsetTop={appBar.current ? appBar.current.clientHeight : 0}
            scrollZoom={false}
          >
            {locations.map((location, index) => (
              <MapLocationPopup
                key={`marker-${index}`}
                location={location}
                order={index + 1}
                showPopup={location.id === openLocation}
                onClickPin={handleClickPin}
                onClosePopup={handleClosePopup}
              />
            ))}
          </MapboxMap>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          lg={3}
          className={classes.locationsContainer}
        >
          <Box
            p={2}
            paddingTop={appBar.current ? appBar.current.clientHeight : 0}
          >
            <Box pt={2}>
              {loadingLocations ? (
                <Box p={4} textAlign="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Box mb={2}>
                    <MapboxLocationAutocomplete
                      onUpdateLocation={handleUpdateLocation}
                    />
                  </Box>
                  <Typography>
                    <strong>{t("home.sites_nearby")}</strong>
                  </Typography>
                  <Typography>
                    {locations.length} {t("home.results_found")}.
                  </Typography>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  {locations.length > 0 ? (
                    <LocationsList locations={locations} />
                  ) : (
                    <LocationsComingSoon />
                  )}
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export { Home };
