import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";

const useClasses = makeStyles((theme) => ({
  button: {
    fontWeight: 700,
  },
}));

const MoreButton = ({ children, ...rest }) => {
  const classes = useClasses();

  return (
    <Button className={classes.button} endIcon={<ArrowForward />} {...rest}>
      {children}
    </Button>
  );
};

export { MoreButton };
